<template>
    <div class="w-screen overflow-y-hidden header-container bg-pink-14 fixed font-poppins">
        <div class="w-full px-8 mobileL:px-12 h-full">
            <div class="flex justify-between items-center h-full">
                <img src="../../assets/images/home-page/Lay-Bare-Plus.png" alt="Lay Bare Plus Brand" class="w-10 mobileL:w-20 xl:w-24 2xl:w-32">   
                <div class="hidden md:flex md:items-center gap-8 text-white-1 font-semibold">
                    <router-link class="hover:text-violet-1" to="/laybareplus">HOME</router-link>
                    <router-link class="hover:text-violet-1" to="/laybareplus/branches">LOCATION</router-link>
                    <router-link class="hover:text-violet-1" to="/laybareplus/services">SERVICES</router-link>
                    <router-link class="hover:text-violet-1" to="/laybareplus/products">PRODUCTS</router-link>
                    <a class="hover:text-violet-1" href="https://franchise.lay-bare.com/">FRANCHISE</a>
                    <router-link class="hover:text-violet-1" to="/app">APP</router-link>
                    <router-link class="hover:text-violet-1" to="/laybareplus/egift">E-GIFT</router-link>
                </div>
                <button @click="toggleMenu" class="md:hidden focus:outline-none" v-if="!isMenuOpen">
                    <i class="fas fa-bars text-3xl mt-1 text-black-3" />
                </button>
            </div>
        </div>
        <div v-if="isMenuOpen" class="fixed inset-0 bg-gradient-to-b from-pink-14 to-pink-14 hamburger-menu-height">
            <div class="mx-auto px-1 flex flex-col h-screen">
                <button @click="toggleMenu" class="absolute right-8 top-4 fas fa-times text-4xl text-black-3"></button>
                <div class="mt-20">
                    <div class="flex mx-auto gap-2 mobileS:gap-4 items-center justify-center">
                        <a @click="changeBrand('laybare')" class="business-unit-dimension p-1 flex bg-green-14 rounded-full cursor-pointer">
                            <img class="w-full mt-1" src="../../assets/images/home-page/header/logo2.png" alt="Lay Bare">
                        </a>
                        <a @click="changeBrand('laybareplus')" class="business-unit-dimension p-1 flex bg-green-13 rounded-full cursor-pointer">
                            <img class="w-full mt-2 pt-0.5" src="../../assets/images/home-page/Lay-Bare-Plus.png" alt="Lay Bare Plus">
                        </a>
                        <a @click="changeBrand('passionails')" class="business-unit-dimension p-2.5 flex bg-violet-4 rounded-full cursor-pointer">
                            <img class="w-full m-auto" src="../../assets/images/home-page/passionails-logo-cropped.png" alt="Passionails">
                        </a>
                        <a @click="changeBrand('lavish-lashes')" class="business-unit-dimension p-2.5 flex bg-red-3 rounded-full cursor-pointer">
                            <img class="w-full m-auto" src="../../assets/images/home-page/lavishlashes-logo.png" alt="Lavish Lashes">
                        </a>
                    </div>
                </div>
                <div class="flex flex-col items-start gap-3 mobileS:gap-4 ml-10 mt-10 text-white-1 hamburger-menu-text-size font-semibold">
                    <button class="font-semibold" @click="navigate('/laybareplus')">Home</button>
                    <button class="font-semibold" @click="navigate('/laybareplus/branches')">Location</button>
                    <button class="font-semibold" @click="navigate('/laybareplus/services')">Services</button>
                    <button class="font-semibold" @click="navigate('/laybareplus/products')">Products</button>
                    <a class="font-semibold" href="https://franchise.lay-bare.com/">Franchise</a>
                    <button class="font-semibold" @click="navigate('/app')">App</button>
                    <button class="font-semibold" @click="navigate('/laybareplus/egift')">E-Gift</button>
                </div>
                <div class="absolute icons-bottom-location justify-center w-full -ml-2 z-10">
                    <div class="flex items-center justify-center shrink-0">
                        <ol class="flex gap-4">
                            <li><a href="https://www.facebook.com/laybareplus" class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 41 41" fill="none">
                                <circle cx="20.5" cy="20.5" r="19" stroke="white" stroke-width="3"/>
                                <path d="M23.1034 9C21.4115 9 19.7889 9.6675 18.5926 10.8557C17.3962 12.0438 16.7241 13.6553 16.7241 15.3356V18.863H13.3103C13.1393 18.863 13 19 13 19.1712V23.8288C13 23.9986 13.1379 24.137 13.3103 24.137H16.7241V33.6918C16.7241 33.8616 16.8621 34 17.0345 34H21.7241C21.8952 34 22.0345 33.863 22.0345 33.6918V24.137H25.4786C25.6207 24.137 25.7448 24.0411 25.7793 23.9041L26.9517 19.2466C26.9633 19.2011 26.9642 19.1537 26.9545 19.1078C26.9448 19.0619 26.9247 19.0189 26.8957 18.9819C26.8667 18.9449 26.8296 18.915 26.7872 18.8944C26.7448 18.8738 26.6982 18.863 26.651 18.863H22.0345V15.3356C22.0345 15.1962 22.0621 15.0581 22.1159 14.9293C22.1696 14.8005 22.2483 14.6835 22.3476 14.5849C22.4468 14.4863 22.5647 14.4081 22.6944 14.3548C22.8241 14.3014 22.9631 14.274 23.1034 14.274H26.6897C26.8607 14.274 27 14.137 27 13.9658V9.30822C27 9.13836 26.8621 9 26.6897 9H23.1034Z" fill="white"/>
                                </svg>
                            </a></li>
                            <li><a href="https://www.instagram.com/laybareplus/" class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 41 41" fill="none">
                                <circle cx="20.5" cy="20.5" r="19" stroke="white" stroke-width="3"/>
                                <path d="M15.67 9H25.33C29.01 9 32 11.99 32 15.67V25.33C32 27.099 31.2973 28.7955 30.0464 30.0464C28.7955 31.2973 27.099 32 25.33 32H15.67C11.99 32 9 29.01 9 25.33V15.67C9 13.901 9.70273 12.2045 10.9536 10.9536C12.2045 9.70273 13.901 9 15.67 9ZM15.44 11.3C14.342 11.3 13.289 11.7362 12.5126 12.5126C11.7362 13.289 11.3 14.342 11.3 15.44V25.56C11.3 27.8485 13.1515 29.7 15.44 29.7H25.56C26.658 29.7 27.711 29.2638 28.4874 28.4874C29.2638 27.711 29.7 26.658 29.7 25.56V15.44C29.7 13.1515 27.8485 11.3 25.56 11.3H15.44ZM26.5375 13.025C26.9187 13.025 27.2844 13.1765 27.554 13.446C27.8236 13.7156 27.975 14.0813 27.975 14.4625C27.975 14.8437 27.8236 15.2094 27.554 15.479C27.2844 15.7485 26.9187 15.9 26.5375 15.9C26.1562 15.9 25.7906 15.7485 25.521 15.479C25.2514 15.2094 25.1 14.8437 25.1 14.4625C25.1 14.0813 25.2514 13.7156 25.521 13.446C25.7906 13.1765 26.1562 13.025 26.5375 13.025ZM20.5 14.75C22.025 14.75 23.4875 15.3558 24.5659 16.4341C25.6442 17.5125 26.25 18.975 26.25 20.5C26.25 22.025 25.6442 23.4875 24.5659 24.5659C23.4875 25.6442 22.025 26.25 20.5 26.25C18.975 26.25 17.5125 25.6442 16.4341 24.5659C15.3558 23.4875 14.75 22.025 14.75 20.5C14.75 18.975 15.3558 17.5125 16.4341 16.4341C17.5125 15.3558 18.975 14.75 20.5 14.75ZM20.5 17.05C19.585 17.05 18.7075 17.4135 18.0605 18.0605C17.4135 18.7075 17.05 19.585 17.05 20.5C17.05 21.415 17.4135 22.2925 18.0605 22.9395C18.7075 23.5865 19.585 23.95 20.5 23.95C21.415 23.95 22.2925 23.5865 22.9395 22.9395C23.5865 22.2925 23.95 21.415 23.95 20.5C23.95 19.585 23.5865 18.7075 22.9395 18.0605C22.2925 17.4135 21.415 17.05 20.5 17.05Z" fill="white"/>
                                </svg>
                            </a></li>
                            <li><a href="https://twitter.com/laybareph" class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 41 41" fill="none">
                                <circle cx="20.5" cy="20.5" r="19" stroke="white" stroke-width="3"/>
                                <path d="M30.92 14C30.15 14.35 29.32 14.58 28.46 14.69C29.34 14.16 30.02 13.32 30.34 12.31C29.51 12.81 28.59 13.16 27.62 13.36C26.83 12.5 25.72 12 24.46 12C22.11 12 20.19 13.92 20.19 16.29C20.19 16.63 20.23 16.96 20.3 17.27C16.74 17.09 13.57 15.38 11.46 12.79C11.09 13.42 10.88 14.16 10.88 14.94C10.88 16.43 11.63 17.75 12.79 18.5C12.08 18.5 11.42 18.3 10.84 18V18.03C10.84 20.11 12.32 21.85 14.28 22.24C13.6507 22.4122 12.9901 22.4362 12.35 22.31C12.6216 23.1625 13.1535 23.9084 13.871 24.4429C14.5885 24.9775 15.4554 25.2737 16.35 25.29C14.8336 26.4904 12.954 27.1393 11.02 27.13C10.68 27.13 10.34 27.11 10 27.07C11.9 28.29 14.16 29 16.58 29C24.46 29 28.79 22.46 28.79 16.79C28.79 16.6 28.79 16.42 28.78 16.23C29.62 15.63 30.34 14.87 30.92 14Z" fill="white"/>
                                </svg>
                            </a></li>
                            <li><a href="https://www.tiktok.com/@officiallaybare" class="w-10 h-10 mx-auto">
                                <img class="lbplus-tiktok-logo" src="../../assets/images/tiktok.png" alt="">
                            </a></li>
                        </ol>
                    </div>
                    <div class="flex justify-center mobileS:mt-2">
                        <img src="../../assets/images/home-page/lb-plus-logo-2.png" alt="Lay Bare Brand" class="w-20 xs:w-24 z-10">
                    </div>
                </div>
                <img src="../../assets/images/home-page/lbplus-menu-element.png" alt="" class="absolute bottom-0 left-0 z-0">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isMenuOpen: false,
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        changeBrand(brand) {
            let path = this.$route.fullPath.split('/');
            path[1] = brand;
            const finalPath = path.join('/');
            this.$router.push(finalPath);
        },
        navigate(path) {
            this.$router.push(path);
            this.toggleMenu();
        },
    },
};
</script>

<style scoped>
.header-container {
  height: 90px;
  font-size: 18px;
}

@media (max-width: 480px) {
  .header-container {
    height: 60px;
    font-size: 14px;
  }
}

.lbplus-tiktok-logo {
    height: 32px;
    width: 32px;
}

.icons-bottom-location {
    bottom: 64px;
}

.hamburger-menu-text-size {
    font-size: 24px
}

@media (max-height: 670px) {
    .icons-bottom-location {
        bottom: 40px;
    }
    .hamburger-menu-text-size {
        font-size: 20px
    }
}

.hamburger-menu-height {
    height: 100vh;
    overflow: hidden;
}

.business-unit-dimension {
  height: 70px;
  width: 70px;
}

@media (max-width: 320px) {
  .business-unit-dimension {
    height: 56px;
    width: 56px;
  }
}

@media (min-width: 1280px) {
    .header-container {
        height: 100px;
        font-size: 20px;
    }
}

@media (min-width: 1536px) {
    .header-container {
        height: 128px;
        font-size: 24px;
    }
}
</style>